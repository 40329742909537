#more-services {
  background: url("../assets/more-services-bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

#more-services:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(8, 10, 11, 0.7);
}

@media (min-width: 1024px) {
  #more-services {
    background-attachment: fixed;
  }
}

#more-services .scissors i {
  left: 50%;
  transform: translateX(-50%);
  bottom: 7px;
  position: absolute;
  font-size: 20px;
  color: var(--primary);
}

#more-services .scissors {
  font-weight: 100;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 0px;
  text-transform: uppercase !important;
}

#more-services p,
h3 {
  position: relative;
}

.service-1 {
  text-align: center;
}
.service-1 .service-1-icon {
  background: var(--primary);
  width: 90px;
  height: 90px;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  border: 4px solid var(--primary);
  border-radius: 50%;
  color: #fff;
}
.service-1 .service-1-icon > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  filter: invert(100%);
}
.service-1 .service-1-contents h3,
.service-1 .service-1-contents p {
  color: #fff !important;
}
.service-1 .service-1-contents h3 {
  margin-bottom: 10px;
  font-size: 20px;
}
