.item-1 {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  background: #fff;
}
.item-1 .item-1-contents {
  padding: 30px;
  border: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  position: relative;
}
.item-1 .item-1-contents ul {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.item-1 .item-1-contents ul li {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
}
.item-1 .item-1-contents ul li .price {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  text-align: right;
}
.item-1 .item-1-contents h3 {
  position: relative;
  color: var(--primary);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.item-1 .item-1-contents h3:after {
  left: 0;
  bottom: 0;
  content: "";
  width: 40px;
  height: 2px;
  background: var(--primary);
  position: absolute;
}

.swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

span.swiper-pagination-bullet,
span.swiper-pagination-bullet-active {
  margin: 3px !important;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--primary);
}

#services .scissors i {
  left: 50%;
  transform: translateX(-50%);
  bottom: 7px;
  position: absolute;
  font-size: 20px;
  color: var(--primary);
}

#services .scissors {
  font-weight: 100;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 0px;
  text-transform: uppercase !important;
}
