.btn-second,
.btn-second:focus {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: var(--primary);
}

.btn-second:hover,
.btn-second:focus:hover {
  color: #fff;
  background: var(--primaryHover);
}
