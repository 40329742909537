#coming-soon {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../assets/not-found-page-bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
}

#coming-soon:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(8, 10, 11, 0.7);
}

#coming-soon .logo img {
  position: relative;
  max-height: 70px;
}

#coming-soon .content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#coming-soon h1 {
  position: relative;
  text-align: center;
  font-size: 300px;
  color: rgba(255, 255, 255, 0.6);
}

#coming-soon h2 {
  position: relative;
  text-align: center;
  font-size: 100px;
  color: rgba(255, 255, 255, 0.6);
}

#coming-soon h3 {
  position: relative;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

@media (max-height: 500px) {
  #coming-soon {
    height: 150vh;
  }
}

@media (max-width: 1024px) {
  #coming-soon h1 {
    font-size: 200px;
  }
}
