/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

#contact .scissors i {
  left: 50%;
  transform: translateX(-50%);
  bottom: 7px;
  position: absolute;
  font-size: 20px;
  color: var(--primary);
}

#contact .scissors {
  font-weight: 100;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 0px;
  text-transform: uppercase !important;
}

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: var(--primary);
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted var(--secondary);
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 40px;
}

.contact .php-email-form .error-message {
  display: block;
  color: #fff;
  background: var(--primary);
  text-align: left;
  padding: 15px;
  font-weight: 600;
  transition: ease-in-out 0.5s;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: block;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  transition: ease-in-out 0.5s;
}

.contact .php-email-form .loading {
  display: block;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--primary);
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: var(--primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: var(--primaryHover);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
