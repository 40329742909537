/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h2 {
  font-weight: 100;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.about .content h3 {
  font-weight: 100;
  line-height: 32px;
  font-size: 24px;
}

.about .content .scissors i {
  left: 50%;
  transform: translateX(-50%);
  bottom: 7px;
  position: absolute;
  font-size: 20px;
  color: var(--primary);
}

.about .content p:last-child {
  margin-bottom: 0;
}

.img-years {
  position: relative;
  display: block;
}
.img-years .year {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--primary);
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(50%, 50%);
  -ms-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
@media (max-width: 991.98px) {
  .img-years .year {
    position: relative;
    -webkit-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    margin: -30px auto 0 auto;
  }
}
.img-years .year > span {
  width: 100%;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
  display: block;
  line-height: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.img-years .year > span > span {
  line-height: 1.2;
  display: block;
  font-size: 0.8rem;
}

.scissors {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-size: 3rem;
  color: var(--primary);
}
@media (max-width: 991.98px) {
  .scissors {
    font-size: 2rem;
  }
}
