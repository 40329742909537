/*--------------------------------------------------------------
# Global
--------------------------------------------------------------*/
:root {
  --primary: #bb2648;
  --secondary: #f0d8de;
  --primaryHover: #ea0f42;
}
/*--------------------------------------------------------------
# body
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: var(--primary);
  text-decoration: none !important;
}

a:hover {
  color: var(--primaryHover);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}
